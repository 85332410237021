import {createRoot} from "react-dom/client";
import React from "react";
import Container from "./components/container";


export default function UIStarter(){
	
	const root = createRoot(document.getElementById("container"));
	root.render(<Container/>)
	
} 