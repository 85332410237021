import {ERange_Types} from "./config";

export default function matchMessage(status: ERange_Types){
	
	switch (status){
		case ERange_Types.ACTIVE:
			return {message: "Prawdopodobnie jesteś w zasięgu internetu światłowodowego! Zamów interesujące Cię usługi, korzystając z naszego konfiguratora.", button: `Zamów`, color: "success", action: "order", type: ERange_Types.ACTIVE};
		case ERange_Types.OUTRANGE_PERMANENT:
			return {message: "Aktualnie znajdujesz się poza zasięgiem naszych usług. Być może w przyszłości pojawią się kolejne rozbudowy, zachęcamy do pozostania w kontakcie!", button: `Zapisz się`, color: "warning", action: "outrange_permanent", type: ERange_Types.OUTRANGE_PERMANENT }
		case ERange_Types.OUTRANGE:
			return {message: "Na ten moment niestety nie mamy możliwości przyłączenia do sieci światłowodowej w Twojej lokalizacji. Zapisz się na listę zainteresowanych.", button: `Zapisz się`, color: "warning", action: "interested", type: ERange_Types.OUTRANGE }
		
		case ERange_Types.KPO:
			return {message: "Jesteś objęty rozbudową w ramach KPO. Zapisz się już teraz na listę oczekujących!", button: `Zapisz się`, color: "success", action: "interested", type: ERange_Types.KPO}
		case ERange_Types.PLANNING:
			return {message:"Adres w zasięgu planowanej rozbudowy sieci światłowodowej. Zapisz się na listę oczekujących, a my poinformujemy Cię o gotowości podłączenia do naszej sieci",  button: `Zapisz się`, color: "success", action: "interested", type: ERange_Types.PLANNING}
		case ERange_Types.BUILDING:
			return {message: "Nasz ultraszybki internet światłowodowy niebawem będzie dostępny w Twojej lokalizacji! Zapisz się na listę oczekujących, a my poinformujemy Cię o gotowości podłączenia do naszej sieci po ukończeniu trwającej rozbudowy.",  button: `Zapisz się`, color: "success",action: "interested", type: ERange_Types.BUILDING}
		case ERange_Types.RADIO:
			return {message: "Prawdopodobnie jesteś w zasięgu internetu radiowego! Zamów interesujące Cię usługi, korzystając z naszego konfiguratora.",   button: `Zamów`, color: "success", action: "order", type: ERange_Types.RADIO}
		case ERange_Types.TAURON:
			return {message: "Prawdopodobnie jesteś w zasięgu sieci światłowodowej na infrastrukturze Tauron. Aby poznać szczegóły oferty, przejdź do konfiguratora.",  button: `Zamów`, color: "success", action: "order", type: ERange_Types.TAURON}
		case ERange_Types.SPECIAL:
			return {message: "Prawdopodobnie jesteś w zasięgu internetu światłowodowego objętego ofertą specjalną! Zamów interesujące Cię usługi, korzystając z naszego konfiguratora.", button: `Zamów`, color: "success", action: "order", type: ERange_Types.SPECIAL};
		case ERange_Types.TOYA:
			return {message: "Prawdopodobnie jesteś w zasięgu sieci światłowodowej na infrastrukturze TOYA. Aby poznać szczegóły oferty, przejdź na stronę naszego Partnera", button: "Sprawdź ofertę TOYA", color: "success", action: "toya", type: ERange_Types.TOYA};
		
	}
	
	
}