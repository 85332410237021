import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	FormControlLabel,
	Grid,
	InputAdornment,
	TextField,
	Typography,
	Checkbox
} from "@mui/material";
import React, {useState} from "react";
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import SmartphoneIcon from '@mui/icons-material/Smartphone';
import EmailIcon from '@mui/icons-material/Email';
import MessageIcon from "@mui/icons-material/Message";
import RedeemIcon from '@mui/icons-material/Redeem';
import VerifyTel from "./verify_tel";
import {enqueueSnackbar} from "notistack";
import axios from "axios";
import {ERange_Types} from "../config";
interface Props {
	closePopup: any;
	message: any;
	address: any;
}

export default function DialogPopup(props: Props) {
	
	const [name, setName] = useState("");
	const [surname, setSurname] = useState("");
	const [phone_number, setPhoneNumber] = useState("");
	const [email, setEmail] = useState("");
	const [message, setMessage] = useState("");
	const [voucher, setVoucher] = useState("");
	const [rodo, setRodo] = useState(false);
	const [tos, setTos] = useState(false);
	const [rodo_tos_error, toggleRodoTosError] = useState(false);
	
	
	const [verify_tel, toggleVerifyTel] = useState(false);
	
	
	const toggleVerify = () => {
		if(!tos || !rodo){
			toggleRodoTosError(true);
		}else {
			toggleRodoTosError(false);
			toggleVerifyTel(!verify_tel);
		}
	}
	
	const saveWithVerify = async (code)=>{
		try{
			let query = await axios.post('/v1/verify_code', {phone: phone_number, code: code});
			if (!query.data.error) {
				enqueueSnackbar({message: "Kod SMS poprawny. Zapisujemy Cię na listę zainteresowanych ", variant:'success'});
				let query_save = await axios.post("/v1/save", {
					name: name,
					surname: surname,
					phone: phone_number,
					email: email,
					message: message,
					voucher: voucher,
					county: props.address.county,
					city: props.address.city,
					street: props.address.street,
					place_number: props.address.place_number,
					max_range_type: props.message.type
				})
				
				if(!query_save.data.error){
					toggleVerify();
					enqueueSnackbar({message: "Zapisaliśmy Cię na listę zainteresowanych. ", variant:'success'});
					props.closePopup()
				}
			} else {
				enqueueSnackbar({message: query.data.message, variant: 'error'});
			}
			
			
		}catch(e){
			enqueueSnackbar({message: 'Nie możemy zweryfikować SMS. Spróbuj ponownie później.', variant: 'error'});
			
		}
		
		
		
		
	}
	

	
	const sendSMS = async ()=>{
		try {
			let query = await axios.post('/v1/send_code', {phone: phone_number});
			if (!query.data.error) {
				enqueueSnackbar({message: "Wysłano SMS", variant: 'info'});
			} else {
				enqueueSnackbar({message: query.data.message, variant: 'error'});
			}
		}catch(e){
			enqueueSnackbar({message: 'Nie możemy wysłać SMS. Spróbuj ponownie później.', variant: 'error'});
		}
	}
	const validateEmail = (email) => {
		return String(email)
			.toLowerCase()
			.match(
				/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
			);
	};
	console.log(props.message.type);
	return <><Dialog open={true} fullWidth maxWidth={'sm'} onClose={() => {
		props.closePopup();
	}}>
		<DialogTitle>
			Zapisz się na listę zainteresowanych
		</DialogTitle>
		<DialogContent>
			
			<Grid container spacing={1}>
				<Grid item lg={12} xs={12} sm={12} md={12} xl={12}>
					{props.message.type == ERange_Types.KPO && <Typography variant={'caption'}>Aby móc skorzystać z oferty specjalnej związanej z projektem rozbudowy w ramach KPO, zostaw nam swoje dane kontaktowe i zapisz się na listę oczekujących. O postępach prac związanych z rozbudową naszej sieci, będziemy na bieżąco informować zapisane osoby.</Typography>}
					{props.message.type == ERange_Types.OUTRANGE && <Typography variant={'caption'}>Na ten moment niestety nie mamy możliwości przyłączenia do sieci światłowodowej w Twojej lokalizacji. Zostaw nam swoje dane kontaktowe, a my poinformujemy Cię, jeśli w przyszłości sieć zostanie rozbudowana i pojawi się możliwość świadczenia usług pod Twoim adresem.</Typography>}
					{(props.message.type == ERange_Types.BUILDING || props.message.type == ERange_Types.PLANNING) && <Typography variant={'caption'}>Nasz ultraszybki internet światłowodowy niebawem będzie dostępny w Twojej lokalizacji! Zostaw nam swoje dane kontaktowe, a my poinformujemy Cię, kiedy rozbudowa zostanie ukończona i pojawi się możliwość przyłączenia do sieci.</Typography> }
				</Grid>
				<Grid item lg={6} xs={12} sm={12} md={12} xl={6}>
			
					<TextField fullWidth 
					           variant={'outlined'}
					           label={"Imię*"} 
					           value={name} 
					           type={'text'}
					           margin={'normal'}
					           error={name.length > 1 && name.length < 3}
					           helperText={(name.length > 1 && name.length < 3) && "Imię musi mieć conajmniej 3 litery"}
					           InputProps={{
						           startAdornment: <InputAdornment position="start"><AccountCircleIcon/></InputAdornment>,
					           }}
					           onChange={(e) => {
						           setName(e.target.value);
					           }}
					           
					           
					/>
				</Grid>
				<Grid item lg={6} xs={12} sm={12} md={12} xl={6}>
					<TextField fullWidth 
					           variant={'outlined'} 
					           label={'Nazwisko*'}
					           margin={'normal'}
					           value={surname} 
					           type={'text'}
					           InputProps={{
						           startAdornment: <InputAdornment position="start"><AccountCircleIcon/></InputAdornment>,
					           }}
					           error={surname.length >1 && surname.length < 3}
					           helperText={(surname.length > 1 && surname.length < 3) && "Nazwisko musi mieć minimum 3 litery"}
					           onChange={(e) => {
						           setSurname(e.target.value);
					           }}/>
				</Grid>
				<Grid item lg={6} xs={12} sm={12} md={12} xl={6}>
					<TextField fullWidth
					           variant={'outlined'}
					           label={'Numer Telefonu*'}
					           value={phone_number}
					           inputMode={'tel'}
					           autoComplete={"tel"}
					           margin={'normal'}
					           error={(phone_number.length >1 && phone_number.length < 9) ||(phone_number.length == 9 &&  isNaN(parseInt(phone_number))) }
					           helperText={((phone_number.length >1 && phone_number.length < 9) ||(phone_number.length == 9 &&  isNaN(parseInt(phone_number))) )&& "Podaj prawidłowy numer telefonu."}
					           InputProps={{
						           startAdornment: <InputAdornment position="start"><SmartphoneIcon/>+48</InputAdornment>,
					           }}
					           inputProps={{
						           maxLength: 9,
						           inputMode: "numeric",
					           }}
					           onChange={(e) => {
						           setPhoneNumber(e.target.value);
					           }}/>
				</Grid>
				<Grid item lg={6} xs={12} sm={12} md={12} xl={6}>
					<TextField fullWidth 
					           variant={'outlined'} 
					           label={'Email*'} 
					           value={email}
					           margin={'normal'}
					           type={'email'}
					           autoComplete={"email"}
					           error={email.length > 5 && !validateEmail(email)}
					           helperText={( email.length > 5 && !validateEmail(email))&& "Podaj prawidłowy email."}
					           InputProps={{
						           startAdornment: <InputAdornment position="start"><EmailIcon/></InputAdornment>,
					           }}
					           onChange={(e) => {
						           setEmail(e.target.value);
					           }}/>
				</Grid>
				<Grid item xs={12}>
					<TextField fullWidth
					           variant={'outlined'}
					           label={'Voucher'}
					           value={voucher}
					           placeholder={'Numer seryjny'}
					           inputProps={{
											 maxLength: 60
					           }}
					           InputProps={{
						           startAdornment: <InputAdornment position="start"><RedeemIcon/></InputAdornment>,
					           }}
					           onChange={(e) => {
						           setVoucher(e.target.value);
					           }}/>
				</Grid>
				<Grid item xs={12}>
					<TextField fullWidth
					           multiline
					           minRows={1}
					           maxRows={10}
					           variant={'outlined'}
					           label={'Wiadomość (opcjonalnie)'}
					           value={message}
					         
					           InputProps={{
						           startAdornment: <InputAdornment position="start"><MessageIcon/></InputAdornment>,
					           }}
					           onChange={(e) => {
						           setMessage(e.target.value);
					           }}/>
				</Grid>
				
				<Grid item lg={6} xs={12}>
					<TextField fullWidth disabled variant={'outlined'} value={props.address.county} label={"Powiat"}/>
				</Grid>
				
				<Grid item lg={6} xs={12}>
					<TextField fullWidth disabled variant={'outlined'} value={props.address.city} label={"Miejscowość"}/>
				</Grid>
				{props.address.street != "" &&
			<Grid item lg={6} xs={12}>
				<TextField fullWidth disabled variant={'outlined'} value={props.address.street} label={isNaN(parseFloat(props.address.street)) ? "Ulica": "Lokalizacja"}/>
			</Grid>}
				<Grid item lg={6} xs={12}>
					<TextField fullWidth disabled variant={'outlined'} value={props.address.place_number}
					           label={isNaN(parseFloat(props.address.street)) ? "Numer budynku": "Lokalizacja"}/>
				</Grid>
				{rodo_tos_error && <Grid item xs={12}>
			<Typography variant={'caption'} color={'error'}>Musisz wyrazić zgody, aby móc się zapisać. </Typography>
				</Grid>}
				<Grid item xs={12}>
				
					<FormControlLabel
						value="end"
					
						control={<Checkbox checked={rodo} onChange={(e, changed)=>{setRodo(changed)}} />}
						label={<Typography variant={'caption'}>Wyrażam zgodę na przetwarzanie przez Fiberlink Sp. z o.o. moich danych osobowych wskazanych w niniejszym formularzu w celu wynikającym z funkcji niniejszego formularza, w tym w celu marketingu bezpośredniego produktów lub usług Fiberlink Sp. z o.o. Zgoda wyrażona przeze mnie ma charakter świadomy i dobrowolny.</Typography>}
						labelPlacement="end"
					/>
				</Grid>
				
				<Grid item xs={12}>
					<FormControlLabel
						value="end"
					
						control={<Checkbox checked={tos}   onChange={(e, changed)=>{setTos(changed)}}  />}
						label={<Typography variant={'caption'}>Zapoznałem/am się i akceptuję zasady dotyczące przetwarzania danych osobowych przez Fiberlink Sp. z o.o. zawarte w: <a href={'https://fiberlink.pl/polityka-prywatnosci/\n'} target={'_blank'}>Polityce Prywatności</a>.</Typography>}
						labelPlacement="end"
					/>
				</Grid>
				
				
				<Grid item xs={12}>
					<Typography variant={'caption'}>* - pole wymagane</Typography>
				</Grid>
			</Grid>
			
			
		
		</DialogContent>
		<DialogActions>
			<Button variant={'contained'} color={'success'}
			        disabled={name.length < 3 || surname.length < 3 || phone_number.length != 9 || isNaN(parseInt(phone_number)) || email.length < 5 || !validateEmail(email)} onClick={toggleVerify}>Zapisz się!</Button>
		</DialogActions>
	</Dialog>
	
	{verify_tel && <VerifyTel phone_number={phone_number} saveWithVerify={saveWithVerify} close={toggleVerify}  sendSMS={sendSMS}/>}
		
		</>
}