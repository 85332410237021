import {SearchPopup} from "./search_popup";
import React, {useEffect, useState} from "react";
import {enqueueSnackbar, SnackbarProvider} from "notistack";
import axios from "axios";
import {Button, Tooltip} from "@mui/material";
import SearchIcon from '@mui/icons-material/Search';
import matchMessage from "../match_message";
import InfoWindowContent from "./info_window";
import DialogPopup from "./dialog_popup";
import {AdvancedMarker, APIProvider, InfoWindow, Map} from '@vis.gl/react-google-maps';
import {ERange_Types} from "../config";
import InfoWindowNotFound from "./info_window_not_found";
import GpsNotFixedIcon from '@mui/icons-material/GpsNotFixed';

export default function Container() {
	
	const [search_open, toggleSearch] = useState(true);
	const [can_close_search, toggleCanCloseSearch] = useState(false);
	const [zoom, setZoom] = useState(11);
	const [map_pos_lat, setMapPosLat] = useState(50.00);
	const [map_pos_lng, setMapPosLng] = useState(19.90);
	
	const [placemark, togglePlacemark] = useState(false);
	const [info_window, toggleInfoWindow] = useState(false);
	const [dialog_popup, toggleDialogPopup] = useState(false);
	
	const [place_long, setPlaceLong] = useState(0);
	const [place_lat, setPLaceLat] = useState(0);
	
	const [address, setAddress] = useState<any>();
	const [message, setMessage] = useState<any>();
	
	useEffect(() => {
		localize();
	}, []);
	const verifyAddress = async (county, city, street, place_number) => {
		try {
			let query = await axios.post(`/v2/public/address/verify`, {
				city: city,
				county: county,
				street: street,
				place_number: place_number
			});
			
			toggleCanCloseSearch(true);
			let address = query.data;
			if (address.exact) {
				await addPlacemark(address.latitude, address.longitude, address);
				setZoom(20);
				setMapPosLng(address.longitude);
				setMapPosLat(address.latitude);
			} else {
				enqueueSnackbar({
					message: 'Nie udało nam się znaleźć podanego adresu. Proszę wybrać miejsce na mapie',
					variant: 'info',
					autoHideDuration: 10000
				});
				setZoom(17);
				setMapPosLng(address.longitude);
				setMapPosLat(address.latitude);
			}
			toggleSearch(false);
			
		} catch (e) {
			enqueueSnackbar({
				message: 'Wystąpił błąd w czasie wyszukiwania adresu. Spróbuj ponownie później',
				variant: 'error'
			});
		}
		
	};
	
	const localize = () => {
		navigator.geolocation.getCurrentPosition(async (position) => {
			await addPlacemark(position.coords.latitude, position.coords.longitude, undefined);
			setZoom(17);
			setMapPosLng(position.coords.longitude);
			setMapPosLat(position.coords.latitude);
			toggleCanCloseSearch(true);
			toggleSearch(false);
			
			
		});
	};
	const addPlacemark = async (latitude, longitude, address) => {
		togglePlacemark(true);
		toggleInfoWindow(false);
		setPlaceLong(longitude);
		setPLaceLat(latitude);
		setAddress({});
		setMessage({});
		if (address == undefined) {
			try {
				let query = await axios.post("/v2/public/address/find", {
					latitude: latitude,
					longitude: longitude,
				});
				
				
				let address = query.data;
				let message = matchMessage(address.additional_info.status);
				
				setAddress(address);
				setMessage(message);
				toggleInfoWindow(true);
			} catch (e) {
				enqueueSnackbar({message: "Nie udało się znaleźć adresu. Spróbuj ponownie później", variant: "error"});
			}
		} else {
			
			let message = matchMessage(address.additional_info.status);
			setAddress(address);
			setMessage(message);
			toggleInfoWindow(true);
			
		}
		
	};
	
	const showPopup = () => {
		toggleDialogPopup(true);
		toggleInfoWindow(false);
		togglePlacemark(false);
		
	};
	
	const closePopup = () => {
		toggleDialogPopup(false);
		toggleInfoWindow(false);
		togglePlacemark(false);
	};
	
	return <>
		<SnackbarProvider anchorOrigin={{vertical: 'bottom', horizontal: 'center'}}/>
		<APIProvider apiKey={"AIzaSyBBEdqYR5DQUTsQZOAtjDYGoT9vGFwygpU"}>
			<Map  mapId={'805b6064bead88f6'} defaultCenter={{lat: 50.00, lng: 19.90}} defaultZoom={11} maxZoom={18} styles={[ {
				featureType: "poi.business",
				stylers: [{ visibility: "off" }],
			},
				{
					featureType: "transit",
					elementType: "labels.icon",
					stylers: [{ visibility: "off" }],
				},]}
			     onClick={(event) => {
				     addPlacemark(event.detail.latLng.lat, event.detail.latLng.lng, undefined);
			     }} zoom={zoom} center={{lat: map_pos_lat, lng: map_pos_lng}} onZoomChanged={(e) => {
				setZoom(e.detail.zoom);
			}} onCameraChanged={(e) => {
				setMapPosLat(e.detail.center.lat);
				setMapPosLng(e.detail.center.lng);
			}}>
				
				{placemark && <AdvancedMarker position={{lat: place_lat, lng: place_long}} draggable={false}></AdvancedMarker>}
				
				{info_window && <InfoWindow position={{lat: place_lat, lng: place_long}} onCloseClick={() => {
					togglePlacemark(false);
					toggleInfoWindow(false);
				}}>
					{message.type == ERange_Types.OUTRANGE_PERMANENT && <InfoWindowNotFound message={message}/>}
					{message.type != ERange_Types.OUTRANGE_PERMANENT &&
			  <InfoWindowContent address={address} message={message} showPopup={showPopup}/>}
		</InfoWindow>}
			
			</Map>
			
			
			<Tooltip title={'Wyszukaj adres'}>
				<Button sx={{position: 'absolute', top: '8vh', left: '10px'}} size={'large'} variant="contained" color="warning"
				        onClick={() => {
					        toggleSearch(true);
				        }}>
					<SearchIcon/>
				</Button>
			</Tooltip>
			<Tooltip title={'Zlokalizuj'}>
				<Button sx={{position: 'absolute', top: '15vh', left: '10px'}} size={'large'} variant="contained"
				        color="warning"
				        onClick={() => {
					        localize();
				        }}>
					<GpsNotFixedIcon/>
				</Button>
			</Tooltip>
			
			<SearchPopup open={search_open} verifyAddress={verifyAddress} close={() => {
				toggleSearch(false);
			}} can_close={can_close_search}/>
			{dialog_popup && <DialogPopup closePopup={closePopup} address={address} message={message}/>}
		
		</APIProvider>
	</>;
}