import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle, FormControl,
	Grid2,
	InputAdornment, InputLabel, OutlinedInput,
	Typography
} from "@mui/material";
import React, {useEffect, useState} from "react";
import VpnKeyIcon from '@mui/icons-material/VpnKey';

interface Props {
	phone_number: string;
	saveWithVerify: any;
	close: any;
	sendSMS: any;
}

export default function VerifyTel(props: Props) {
	
	const [sms_sent, toggleSMSSent] = useState(false);
	const [sms_code, setSMSCode] = useState("");
	const [sms_timeout, setSMSTimeout] = useState(60);
	const [sms_timer, toggleSMSTimer] = useState(false);
	
	
	useEffect(() => {
		if (sms_timer) {
			setTimeout(() => {
				setSMSTimeout(sms_timeout - 1);
			}, 1000);
			if (sms_timeout == 0) {
				toggleSMSTimer(false);
			}
		}
	}, [sms_timer, sms_timeout]);
	const sendSMS = () => {
		props.sendSMS();
		setSMSTimeout(60);
		toggleSMSSent(true);
		toggleSMSTimer(true);
		
		
	};
	
	
	return <> <Dialog open={true} fullWidth maxWidth={'sm'}>
		
		<DialogTitle>
			Potwierdź swój numer telefonu
		</DialogTitle>
		
		<DialogContent>
			<Grid2 container spacing={1}>
				<Grid2 size={{xs: 12}}>
					<Typography variant={'body1'}>
						Potwierdzenie numeru telefonu pozwoli nam poinformować Cię o gotowości podłączenia do naszej sieci
						światłowodowej, jeśli tylko pojawi się taka możliwość.
					</Typography>
				</Grid2>
				<Grid2 size={{xs: 8}}>
					<FormControl variant={'outlined'} fullWidth>
						<InputLabel>Numer telefonu</InputLabel>
						<OutlinedInput fullWidth
						               label={'Numer telefonu'}
						               size={'small'}
						               disabled
						               value={props.phone_number}/>
					</FormControl>
				
				</Grid2>
				<Grid2 size={{xs: 4}}>
					<Button variant={'contained'} onClick={sendSMS} disabled={sms_timer}>
						{!sms_timer ? "Wyślij SMS" : `Ponów za ${sms_timeout}`}
					</Button>
				</Grid2>
				{sms_sent &&
			<Grid2 size={{xs: 12}}>
				<FormControl variant={'outlined'} fullWidth>

					<InputLabel>Kod SMS</InputLabel>
					<OutlinedInput fullWidth
					               label={'Kod SMS'}
					               inputMode={'numeric'}
					               autoFocus={true}
					               value={sms_code}
					               onChange={(e) => {
											               setSMSCode(e.target.value);
										               }}
					               startAdornment={<InputAdornment position="start"><VpnKeyIcon/></InputAdornment>}

					               inputProps={{
											               maxLength: 6,
											               inputMode: "numeric",
										               }}

					/>
				</FormControl>
			</Grid2>
				}
			</Grid2>
		</DialogContent>
		
		<DialogActions>
			<Button onClick={props.close}>Wróć</Button>
			<Button onClick={() => {
				props.saveWithVerify(sms_code);
			}}
			        variant={"contained"}
			        color={"success"}
			        disabled={!sms_sent || sms_code.length != 6}>Potwierdzam</Button>
		</DialogActions>
	
	</Dialog>
	</>;
}