
import UIStarter from "./src/ui_starter";

function start(){ 
	
	
//	new Map_Holder()._start();
	
	UIStarter();
	
}

start();