import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	Grid,
	InputAdornment,
	TextField,
	Typography
} from "@mui/material";
import React, {useEffect, useState} from "react";
import VpnKeyIcon from '@mui/icons-material/VpnKey';

interface Props {
	phone_number: string;
	saveWithVerify: any;
	close: any;
	sendSMS: any;
}

export default function VerifyTel(props: Props) {
	
	const [sms_sent, toggleSMSSent] = useState(false);
	const [sms_code, setSMSCode] = useState("");
	const [sms_timeout, setSMSTimeout] = useState(60);
	const [sms_timer, toggleSMSTimer] = useState(false);
	
	
	useEffect(() => {
			if (sms_timer) {
				setTimeout(()=>{
					setSMSTimeout(sms_timeout - 1);
				}, 1000);
				if (sms_timeout == 0) {
					toggleSMSTimer(false);
				}
			}
	}, [sms_timer, sms_timeout]);
	const sendSMS = () => {
		props.sendSMS();
		setSMSTimeout(60);
		toggleSMSSent(true);
		toggleSMSTimer(true);
		
		
	};
	

	
	return <> <Dialog open={true} fullWidth maxWidth={'sm'}>
		
		<DialogTitle>
			Potwierdź swój numer telefonu
		</DialogTitle>
		
		<DialogContent>
			<Grid container spacing={1}>
				<Grid item xs={12}>
					<Typography variant={'body1'}>
						Potwierdzenie numeru telefonu pozwoli nam poinformować Cię o gotowości podłączenia do naszej sieci światłowodowej, jeśli tylko pojawi się taka możliwość.
					</Typography>
				</Grid>
				<Grid item xs={8}>
					<TextField fullWidth
					           variant={'outlined'}
					           label={'Numer telefonu'}
					           size={'small'}
					           disabled
					           value={props.phone_number}/>
				</Grid>
				<Grid item xs={4}>
					<Button variant={'contained'} onClick={sendSMS} disabled={sms_timer}>
						{!sms_timer ? "Wyślij SMS" : `Ponów za ${sms_timeout}`}
					</Button>
				</Grid>
				{sms_sent &&
			<Grid item xs={12}>
				<TextField fullWidth
				           variant={'outlined'}
				           label={'Kod SMS'}
				           inputMode={'numeric'}
				           autoFocus={true}
				           value={sms_code}
				           onChange={(e) => {
									           setSMSCode(e.target.value);
								           }}
				           InputProps={{
									           startAdornment: <InputAdornment position="start"><VpnKeyIcon/></InputAdornment>,
								           }}
				           inputProps={{
									           maxLength: 6,
									           inputMode: "numeric",
								           }}

				/>

			</Grid>
				}
			</Grid>
		</DialogContent>
		
		<DialogActions>
			<Button onClick={props.close}>Wróć</Button>
			<Button onClick={()=>{
				props.saveWithVerify(sms_code);
			}}
			        variant={"contained"}
			        color={"success"}
			        disabled={!sms_sent || sms_code.length != 6}>Potwierdzam</Button>
		</DialogActions>
	
	</Dialog>
	</>;
}