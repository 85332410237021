import React from "react";
import {Button, Typography} from "@mui/material";

interface Props {
	address: {
		city: string,
		street: string,
		place_number: string
	},
	message: {
		message: string,
		button: string,
		color: "success" | "warning",
		action: "interested" | "order" | "toya"
	},
	showPopup: any;
}

export default function InfoWindowContent(props: Props) {
	
	return <>
		
		
		<Typography variant={'h6'}>{props.address.city}, {props.address.street} {props.address.place_number}</Typography>
		<Typography variant={'body1'}>{props.message.message}</Typography>
		
		{props.message.action == "interested" && <Button variant={'contained'} color={props.message.color} onClick={() => {
			props.showPopup(props.address, props.message);
		}}>{props.message.button}</Button>
		}
		
		
		{props.message.action == "order" &&
		<Button variant={'contained'} color={props.message.color} component={'a'} target={'_blank'}
		        href={'https://fiberlink.pl/konfigurator/'}>{props.message.button}</Button>
		}
		{props.message.action == "toya" &&
		<Button variant={'contained'} color={props.message.color} component={'a'} target={'_blank'}
		        href={'https://toya.net.pl/link-to/66c3a547d4f7c49a3c2abc4cdbe15b7b/'}>{props.message.button}</Button>
		}
	
	</>;
	
}