import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	FormControlLabel,
	Grid2,
	InputAdornment,
	TextField,
	Typography,
	Checkbox,
	OutlinedInput,
	FormControl,
	FormHelperText, InputLabel
} from "@mui/material";
import React, {useState} from "react";
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import SmartphoneIcon from '@mui/icons-material/Smartphone';
import EmailIcon from '@mui/icons-material/Email';
import MessageIcon from "@mui/icons-material/Message";
import RedeemIcon from '@mui/icons-material/Redeem';
import VerifyTel from "./verify_tel";
import {enqueueSnackbar} from "notistack";
import axios from "axios";
import {ERange_Types} from "../config";

interface Props {
	closePopup: any;
	message: any;
	address: any;
}

export default function DialogPopup(props: Props) {
	
	const [name, setName] = useState("");
	const [surname, setSurname] = useState("");
	const [phone_number, setPhoneNumber] = useState("");
	const [email, setEmail] = useState("");
	const [message, setMessage] = useState("");
	const [voucher, setVoucher] = useState("");
	const [rodo, setRodo] = useState(false);
	const [tos, setTos] = useState(false);
	const [rodo_tos_error, toggleRodoTosError] = useState(false);
	
	
	const [verify_tel, toggleVerifyTel] = useState(false);
	
	
	const toggleVerify = () => {
		if (!tos || !rodo) {
			toggleRodoTosError(true);
		} else {
			toggleRodoTosError(false);
			toggleVerifyTel(!verify_tel);
		}
	};
	
	const saveWithVerify = async (code) => {
		try {
			await axios.post('/v2/public/interested/code/verify', {phone: phone_number, code: code});
			
			enqueueSnackbar({message: "Kod SMS poprawny. Zapisujemy Cię na listę zainteresowanych ", variant: 'success'});
			await axios.post("/v2/public/interested/save", {
				name: name,
				surname: surname,
				phone: phone_number,
				email: email,
				message: message,
				voucher: voucher,
				county: props.address.county,
				city: props.address.city,
				street: props.address.street,
				place_number: props.address.place_number,
				max_range_type: props.message.type
			});
			
			
			toggleVerify();
			enqueueSnackbar({message: "Zapisaliśmy Cię na listę zainteresowanych. ", variant: 'success'});
			props.closePopup();
			
			
		} catch (e) {
			enqueueSnackbar({message: 'Nie możemy zweryfikować SMS. Spróbuj ponownie później.', variant: 'error'});
			
		}
		
		
	};
	
	
	const sendSMS = async () => {
		try {
			await axios.post('/v2/public/interested/code/send', {phone: phone_number});
			enqueueSnackbar({message: "Wysłano SMS", variant: 'info'});
			
		} catch (e) {
			enqueueSnackbar({message: 'Nie możemy wysłać SMS. Spróbuj ponownie później.', variant: 'error'});
		}
	};
	const validateEmail = (email) => {
		return String(email)
			.toLowerCase()
			.match(
				/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
			);
	};
	return <><Dialog open={true} fullWidth maxWidth={'sm'} onClose={() => {
		props.closePopup();
	}}>
		<DialogTitle>
			Zapisz się na listę zainteresowanych
		</DialogTitle>
		<DialogContent>
			
			<Grid2 container spacing={1}>
				<Grid2 size={{lg: 12, xs: 12, sm: 12, md: 12, xl: 12}}>
					{props.message.type == ERange_Types.KPO &&
			  <Typography variant={'caption'}>Aby móc skorzystać z oferty specjalnej związanej z projektem rozbudowy w
				  ramach KPO, zostaw nam swoje dane kontaktowe i zapisz się na listę oczekujących. O postępach prac
				  związanych z rozbudową naszej sieci, będziemy na bieżąco informować zapisane osoby.</Typography>}
					{props.message.type == ERange_Types.OUTRANGE &&
			  <Typography variant={'caption'}>Na ten moment niestety nie mamy możliwości przyłączenia do sieci
				  światłowodowej w Twojej lokalizacji. Zostaw nam swoje dane kontaktowe, a my poinformujemy Cię, jeśli w
				  przyszłości sieć zostanie rozbudowana i pojawi się możliwość świadczenia usług pod Twoim
				  adresem.</Typography>}
					{(props.message.type == ERange_Types.BUILDING || props.message.type == ERange_Types.PLANNING) &&
			  <Typography variant={'caption'}>Nasz ultraszybki internet światłowodowy niebawem będzie dostępny w Twojej
				  lokalizacji! Zostaw nam swoje dane kontaktowe, a my poinformujemy Cię, kiedy rozbudowa zostanie
				  ukończona i pojawi się możliwość przyłączenia do sieci.</Typography>}
				</Grid2>
				<Grid2 size={{lg: 6, xs: 12, sm: 12, md: 12, xl: 6}} margin={'none'}>
					<FormControl fullWidth margin={'none'}>
						<InputLabel variant={'outlined'} required>Imię</InputLabel>
						<OutlinedInput fullWidth
						               label={"Imię"}
						               required
						               value={name}
						               type={'text'}
						               id={'name'}
						               margin={'none'}
						               error={name.length > 1 && name.length < 3}
						               startAdornment={<InputAdornment position="start"><AccountCircleIcon/></InputAdornment>}
						               onChange={(e) => {
							               setName(e.target.value);
						               }}
						/>
						{(name.length > 1 && name.length < 3) &&
				<FormHelperText variant={'outlined'} color={'error'}>Imię musi mieć conajmniej 3
					litery</FormHelperText>}
					</FormControl>
				
				</Grid2>
				<Grid2 size={{lg: 6, xs: 12, sm: 12, md: 12, xl: 6}} margin={'none'}>
					<FormControl fullWidth margin={'none'}>
						<InputLabel variant={'outlined'} required>Nazwisko</InputLabel>
						<OutlinedInput fullWidth
						               label={"Nazwisko"}
						               required
						               value={surname}
						               type={'text'}
						               id={'surname'}
						               margin={'none'}
						               error={surname.length > 1 && surname.length < 3}
						               startAdornment={<InputAdornment position="start"><AccountCircleIcon/></InputAdornment>}
						               onChange={(e) => {
							               setSurname(e.target.value);
						               }}/>
						{(surname.length > 1 && surname.length < 3) &&
				<FormHelperText>Nazwisko musi mieć minimum 3 litery</FormHelperText>}
					
					</FormControl>
				
				</Grid2>
				<Grid2 size={{lg: 6, xs: 12, sm: 12, md: 12, xl: 6}} margin={'none'}>
					<FormControl fullWidth margin={'none'}>
						<InputLabel variant={'outlined'} required>Numer Telefonu</InputLabel>
						<OutlinedInput fullWidth
						               label={'Numer Telefonu*'}
						               value={phone_number}
						               inputMode={'tel'}
						               autoComplete={"tel"}
						               margin={'none'}
						               error={(phone_number.length > 1 && phone_number.length < 9) || (phone_number.length == 9 && isNaN(parseInt(phone_number)))}
						               inputProps={{
							               maxLength: 9,
							               inputMode: "numeric",
							               
						               }}
						               startAdornment={<InputAdornment position="start"><SmartphoneIcon/>+48</InputAdornment>}
						               onChange={(e) => {
							               setPhoneNumber(e.target.value);
						               }}/>
						{((phone_number.length > 1 && phone_number.length < 9) || (phone_number.length == 9 && isNaN(parseInt(phone_number)))) &&
				<FormHelperText>Podaj prawidłowy numer telefonu.</FormHelperText>}
					</FormControl>
				</Grid2>
				<Grid2 size={{lg: 6, xs: 12, sm: 12, md: 12, xl: 6}}>
					<FormControl fullWidth margin={'none'}>
						<InputLabel variant={'outlined'} required>Email</InputLabel>
						<OutlinedInput fullWidth
						               
						               label={'Email'}
						               value={email}
						               margin={'none'}
						               type={'email'}
						               autoComplete={"email"}
						               error={email.length > 5 && !validateEmail(email)}
						               startAdornment={<InputAdornment position="start"><EmailIcon/></InputAdornment>}
						               
						               onChange={(e) => {
							               setEmail(e.target.value);
						               }}/>
						{(email.length > 5 && !validateEmail(email)) && <FormHelperText>Podaj prawidłowy email.</FormHelperText>}
					</FormControl>
				</Grid2>
				<Grid2 size={{xs: 12,}}>
					<FormControl fullWidth margin={'none'}>
						<InputLabel variant={'outlined'}>Voucher</InputLabel>
						<OutlinedInput fullWidth
						               label={'Voucher'}
						               value={voucher}
						               placeholder={'Numer seryjny'}
						               inputProps={{
							               maxLength: 60,
						               }}
						               startAdornment={<InputAdornment position="start"><RedeemIcon/></InputAdornment>}
						               
						               onChange={(e) => {
							               setVoucher(e.target.value);
						               }}/>
					</FormControl>
				</Grid2>
				<Grid2 size={{xs: 12}}>
					<FormControl fullWidth margin={'none'}>
						<InputLabel variant={'outlined'}>Wiadomość (opcjonalnie)</InputLabel>
					<OutlinedInput fullWidth
					           multiline
					           minRows={1}
					           maxRows={10}
					           label={'Wiadomość (opcjonalnie)'}
					           value={message}
					           startAdornment={<InputAdornment position="start"><MessageIcon/></InputAdornment>}
					           
					           onChange={(e) => {
						           setMessage(e.target.value);
					           }}/>
					</FormControl>
				</Grid2>
				
				<Grid2 size={{lg: 6, xs: 12,}}>
					<TextField fullWidth disabled value={props.address.county} label={"Powiat"}/>
				</Grid2>
				
				<Grid2 size={{lg: 6, xs: 12,}}>
					<TextField fullWidth disabled value={props.address.city} label={"Miejscowość"}/>
				</Grid2>
				{props.address.street != "" &&
			<Grid2 size={{lg: 6, xs: 12,}}>
				<TextField fullWidth disabled value={props.address.street}
				           label={isNaN(parseFloat(props.address.street)) ? "Ulica" : "Lokalizacja"}/>
			</Grid2>}
				<Grid2 size={{lg: 6, xs: 12,}}>
					<TextField fullWidth disabled value={props.address.place_number}
					           label={isNaN(parseFloat(props.address.street)) ? "Numer budynku" : "Lokalizacja"}/>
				</Grid2>
				{rodo_tos_error && <Grid2 size={{xs: 12}}>
			<Typography variant={'caption'} color={'error'}>Musisz wyrazić zgody, aby móc się zapisać. </Typography>
		</Grid2>}
				<Grid2 size={{xs: 12}}>
					
					<FormControlLabel
						value="end"
						
						control={<Checkbox checked={rodo} onChange={(e, changed) => {
							setRodo(changed);
						}}/>}
						label={<Typography variant={'caption'}>Wyrażam zgodę na przetwarzanie przez Fiberlink Sp. z o.o. moich
							danych osobowych wskazanych w niniejszym formularzu w celu wynikającym z funkcji niniejszego formularza, w
							tym w celu marketingu bezpośredniego produktów lub usług Fiberlink Sp. z o.o. Zgoda wyrażona przeze mnie
							ma charakter świadomy i dobrowolny.</Typography>}
						labelPlacement="end"
					/>
				</Grid2>
				
				<Grid2 size={{xs: 12,}}>
					<FormControlLabel
						value="end"
						
						control={<Checkbox checked={tos} onChange={(e, changed) => {
							setTos(changed);
						}}/>}
						label={<Typography variant={'caption'}>Zapoznałem/am się i akceptuję zasady dotyczące przetwarzania danych
							osobowych przez Fiberlink Sp. z o.o. zawarte w: <a href={'https://fiberlink.pl/polityka-prywatnosci/\n'}
							                                                   target={'_blank'}>Polityce
								Prywatności</a>.</Typography>}
						labelPlacement="end"
					/>
				</Grid2>
				
				
				<Grid2 size={{xs: 12,}}>
					<Typography variant={'caption'}>* - pole wymagane</Typography>
				</Grid2>
			</Grid2>
		
		
		</DialogContent>
		<DialogActions>
			<Button variant={'contained'} color={'success'}
			        disabled={name.length < 3 || surname.length < 3 || phone_number.length != 9 || isNaN(parseInt(phone_number)) || email.length < 5 || !validateEmail(email)}
			        onClick={toggleVerify}>Zapisz się!</Button>
		</DialogActions>
	</Dialog>
		
		{verify_tel &&
		<VerifyTel phone_number={phone_number} saveWithVerify={saveWithVerify} close={toggleVerify} sendSMS={sendSMS}/>}
	
	</>;
}