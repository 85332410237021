



export const COUNTIES = ['KRAKOWSKI', 'MYŚLENICKI', 'BOCHEŃSKI', 'PROSZOWICKI', 'WADOWICKI', 'M. KRAKÓW', 'WIELICKI', 'LIMANOWSKI', 'KAZIMIERSKI' ];

export enum ERange_Types {
	OUTRANGE_PERMANENT = 'out_range_permanent',
	OUTRANGE = 'out_range',
	ACTIVE = 'aktywny',
	KPO = 'kpo',
	PLANNING = 'planowany',
	BUILDING = 'w_budowie',
	RADIO = 'radio',
	TAURON = 'tauron',
	SPECIAL = 'specjalny',
	TOYA = 'toya'
}

