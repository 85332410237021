import {Typography} from "@mui/material";
import React from "react";

interface Props {

	message: {
		message: string,
		button: string,
		color: "success" | "warning",
		action: "interested" | "order"
	}
}
export default function InfoWindowNotFound(props: Props){
	
	return <>
		
		
		<Typography variant={'body1'}>{props.message.message}</Typography>
		
	</>;
}