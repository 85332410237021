import {Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField, Typography, Autocomplete, Grid2} from "@mui/material";
import React, {useState} from "react";
import {COUNTIES} from "../config";
import axios from "axios";
import {enqueueSnackbar} from "notistack";


interface Props{ 
	open: boolean;
	verifyAddress: any;
	close: any,
	can_close: any;
}
export function SearchPopup(props:Props){
	
	const [county, setCounty] = useState<string | null>(null);
	const [city, setCity] = useState<string | null>(null);
	const [street, setStreet] = useState<string | null>(null);
	const [place_number, setPlaceNumber] = useState<string >("");
	const [street_locked,toggleStreetLock] = useState(true);
	const [city_locked, toggleCityLock] = useState(true);
	const [place_number_locked, togglePlaceNumberLock] = useState(true);
	const [available_cities, setAvailableCities] = useState([]);
	const [available_streets, setAvailableStreets] = useState([]);
	

	
	const changeCounty = async (county: string)=>{
		setCounty(county);
		toggleCityLock(true);
		setCity("");
		setAvailableCities([]);
		setAvailableStreets([]);
		setStreet("");
		setPlaceNumber("");
		
		try {
			let query = await axios.get(`/v2/search/${county}/cities`);
				setAvailableCities(query.data.cities);
				toggleCityLock(false);
		}catch (e){
			enqueueSnackbar({message: "Nie udało się pobrać listy miejscowości. Spróbuj ponownie później.", variant: "error"})
		}
		
	}
	
	const changeCity = async (city: string)=>{
		setCity(city);
		setAvailableStreets([]);
		togglePlaceNumberLock(true);
		toggleStreetLock(true);
		setPlaceNumber("");
		setStreet("");
		try {
			let query = await axios.get(`/v2/search/${county}/${city}/streets`);
		
			console.log(query.data);
				if(query.data.streets.length == 0){
					togglePlaceNumberLock(false);
				}else {
					setAvailableStreets(query.data.streets);
					toggleStreetLock(false);
				}
		
		}catch (e){
			enqueueSnackbar({message: "Nie udało się pobrać listy ulic. Spróbuj ponownie później.", variant: "error"})
		}
	}
	
	const changeStreet = async (street: string)=>{
		setStreet(street)
		togglePlaceNumberLock(false);
	}
	
	const changePlaceNumber = async (place_number: string)=>{
		setPlaceNumber(place_number)
	}
	
	
	return <>
		<Dialog open={props.open} maxWidth={"md"} fullWidth onClose={()=>{props.can_close && props.close()}}>
			<DialogTitle>
				Podaj swój adres
			</DialogTitle>
			<DialogContent>
				<Typography variant={'h6'}>Sprawdź czy jesteś w zasięgu!</Typography>
				
				<Grid2 container spacing={1}>
					
					<Grid2 size={{lg:6, xs:12, sm:12, md:12, xl:6}}>
						<Autocomplete value={county} onChange={(event, new_value)=>{changeCounty(new_value)}} autoHighlight getOptionLabel={(option)=>option} options={COUNTIES} renderInput={(params)=>(
							<TextField {...params} label={'Powiat'} autoComplete={'new-password'}/>
						)}/>
							
					</Grid2>
					<Grid2 size={{lg:6, xs:12, sm:12, md:12, xl:6}}  >
						<Autocomplete value={city} disabled={city_locked} onChange={(event, new_value)=>{changeCity(new_value)}} autoHighlight getOptionLabel={(option)=>option} options={available_cities} renderInput={(params)=>(
							<TextField {...params} label={'Miejscowość'} autoComplete={'new-password'}/>
						)}/>
					</Grid2>
					<Grid2 size={{lg:6, xs:12, sm:12, md:12, xl:6}}>
						<Autocomplete value={street} disabled={street_locked} onChange={(event, new_value)=>{changeStreet(new_value)}} autoHighlight getOptionLabel={(option)=>option} options={available_streets} renderInput={(params)=>(
							<TextField {...params} label={'Ulica'} autoComplete={'new-password'}/>
						)}/>
					</Grid2>
					<Grid2 size={{lg:6, xs:12, sm:12, md:12, xl:6}}>
						<TextField fullWidth placeholder={"Numer budynku"}  label={"Numer budynku"} variant={'outlined'} value={place_number} type={"text"} onChange={(e)=>{changePlaceNumber(e.target.value)}} disabled={place_number_locked}/>
					</Grid2>
				</Grid2>
			</DialogContent>
			<DialogActions>
				<Button variant={'contained'} color={'success'} disabled={county == null || city == null || place_number == ''} onClick={()=>{
					props.verifyAddress(county, city, street, place_number);
				}}>
					Sprawdź
				</Button>
			</DialogActions>
		</Dialog>
	</>
}